<template>
  <div>
    <div class="header-top flex items-center">
      <a-descriptions :column="{ md: 8, lg: 12, xl: 24 }">
        <a-descriptions-item label="课节名称" :span="24">
          <span>{{ scheduleName }}</span>
          <a-button type="link" @click="copySectionId" v-if="!!this.sectionInfo.uuid">复制课节ID</a-button>
          <a-button type="link" :loading="copyLoading" @click="copyTalkCloudRoomNumber" v-if="!!this.sectionInfo.uuid"
            >复制悟空教室号</a-button
          >
        </a-descriptions-item>
        <a-descriptions-item span="4" label="授课老师">{{ teacherName }}</a-descriptions-item>
        <a-descriptions-item label="上课时间(班级时区)" span="7">
          {{ $moment(startDateTime).format('YYYY-MM-DD HH:mm') }} -
          {{ $moment(endDateTime).format('HH:mm') }}
        </a-descriptions-item>
        <a-descriptions-item label="课节状态" span="3">{{
          getDdlLabel(scheduleOptions.scheduleStatus, status)
        }}</a-descriptions-item>
        <a-descriptions-item label="所属班级" span="10">
          {{ className }}
        </a-descriptions-item>
      </a-descriptions>

      <div>
        <a-button
          ghost
          type="primary"
          v-if="showLinkBtn"
          :loading="teaLinkLoading"
          @click="() => handleCopyClassLink('TEACHER', sectionInfo.teacher.uuid, sectionInfo.teacher.fullName)"
        >
          老师课节链接
        </a-button>
      </div>
    </div>

    <div class="class-wrap">
      <!-- <h3 v-if="!!scheduleName">{{ '课节: ' + scheduleName }} (班级: {{ className }})</h3> -->
      <div style="margin-bottom: 12px">
        <a-radio-group v-model="currentTab">
          <a-radio-button value="stu">课节学员</a-radio-button>
          <a-radio-button value="data">课堂数据</a-radio-button>
        </a-radio-group>
      </div>

      <a-form-model
        :model="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        class="ant-advanced-search-form"
        layout="inline"
      >
        <a-form-model-item label="学生姓名">
          <a-input allow-clear v-model="form.studentName" />
        </a-form-model-item>
        <a-form-model-item label="学号">
          <a-input allow-clear v-model="form.code" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 24 }">
          <a-button style="margin-right: 10px" @click="handleQuery"> 查询 </a-button>
          <a-button
            v-if="status === 'NORMAL' && currentTab === 'stu'"
            style="margin-right: 10px"
            type="primary"
            @click="showAddStudentModal"
          >
            插班
          </a-button>
        </a-form-model-item>
      </a-form-model>

      <a-divider />

      <!-- 学员列表 -->
      <template v-if="currentTab === 'stu'">
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="studentName" slot-scope="text, record">
            <a @click="toStudentDetail(record)">{{ text }}</a>
          </span>
          <span slot="status" slot-scope="text">{{ getDdlLabel(scheduleOptions.stuScheduleStatus, text) }}</span>
          <span slot="stuScheduleStatusReason" slot-scope="text">{{
            getDdlLabel(scheduleOptions.stuScheduleStatusReason, text)
          }}</span>
          <span slot="action" slot-scope="text, record" style="word-break: keep-all">
            <ModalForm
              title="学员请假"
              :visible="leaveModalVisibleUuid === record.uuid"
              :onFinish="(formData) => handleLeaveFinish(formData, record)"
              @cancel="leaveModalVisibleUuid = undefined"
            >
              <a-button
                slot="trigger"
                type="link"
                v-if="record.canLeave"
                :loading="leaveBtnLoading"
                @click="() => handleLeave(record)"
              >
                请假
              </a-button>
              <template slot="form">
                <a-form-item label="请假原因">
                  <a-select
                    placeholder="请选择"
                    :options="leaveOptions"
                    v-decorator="['reason', { rules: [{ required: true, message: '请选择请假原因' }] }]"
                  />
                </a-form-item>
                <a-form-item label="立即调课" v-if="record.courseScheduleRespDTO.classType === 'GROUP'">
                  <a-radio-group v-decorator="['adjustScheduleProcess', { initialValue: 1 }]">
                    <a-radio :value="1">是</a-radio>
                    <a-radio :value="0">
                      <span>否</span>
                      <span style="opacity: 0.6">（留在原班看回放）</span>
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
                <div
                  style="padding: 24px 24px 0; opacity: 0.6"
                  v-if="record.courseScheduleRespDTO.classType === 'PRIVATE'"
                >
                  <p>提示：</p>
                  <p>1、点击确认后，请假完成，系统将自动取消课节，并调整课节进度。</p>
                  <p>2、如后续学员完成预约补课操作，系统将根据补课时间再次自动调整课节进度。</p>
                </div>
              </template>
            </ModalForm>
            <a-button type="link" @click="handleRevokeLeave(record)" v-if="record.canCancel">撤销请假</a-button>
            <a-button
              type="link"
              v-if="
                record.status !== 'CANCELLED' &&
                (userInfo.roleArr.includes('TEACHING_STAFF') || userInfo.roleArr.includes('TEACHING_STAFF_ADMIN'))
              "
              @click="cancelStudent(record.uuid, record.studentRespDTO.fullName)"
            >
              挪出
            </a-button>
            <a-button
              type="link"
              @click="hadnleAdjustschedule(record)"
              v-if="record.canAdjust && record.courseScheduleRespDTO.classType === 'GROUP'"
              >调课</a-button
            >
            <a-button
              v-if="record.status === 'NORMAL'"
              type="link"
              :loading="record.studentRespDTO.uuid === stuLinkLoading"
              @click="
                () => handleCopyClassLink('STUDENT', record.studentRespDTO.uuid, record.studentRespDTO.fullName, record)
              "
              >课节链接
            </a-button>
          </span>
        </a-table>
      </template>
      <!-- 课堂数据 -->
      <template v-if="currentTab === 'data'">
        <h3>老师信息</h3>
        <a-table :columns="dataColumns" :data-source="teacherList" :pagination="false" :loading="dataLoading">
          <div slot="dateLine" style="padding: 12px 0" slot-scope="text, record">
            <date-line
              :startTime="new Date(startDateTime).getTime()"
              :endTime="new Date(endDateTime).getTime()"
              :details="record.inout ? record.inout.detailList : []"
            />
          </div>
          <span slot="stuName" slot-scope="text, record">
            {{ record.name }}
          </span>
        </a-table>
        <div style="margin-bottom: 12px" />
        <h3>学生信息</h3>
        <a-table
          :columns="dataColumns"
          :data-source="studentList"
          :pagination="dataPagination"
          :loading="dataLoading"
          @change="studenttableChange"
        >
          <div slot="dateLine" style="padding: 12px 0" slot-scope="text, record">
            <date-line
              :startTime="new Date(startDateTime).getTime()"
              :endTime="new Date(endDateTime).getTime()"
              :details="record.inout ? record.inout.detailList : []"
            />
          </div>
          <a
            slot="stuName"
            slot-scope="text, record"
            @click="toStudentDetail({ studentRespDTO: { uuid: record.uuid } })"
            >{{ record.name + ' (' + record.code + ')' }}</a
          >
        </a-table>
      </template>

      <a-modal v-model="addStudentVisible" title="添加学生" @ok="addStudent">
        <a-form-model>
          <a-form-model-item>
            <a-select
              v-model="studentId"
              placeholder="请选择学生(支持学号，姓名搜索)"
              show-search
              @search="fetchStudent"
              :filter-option="false"
              :dropdown-match-select-width="false"
              option-label-prop="label"
              :not-found-content="null"
              allow-clear
            >
              <a-select-option v-for="item in studentOptions" :key="item.id" :value="item.id" :label="item.name">
                <div style="display: flex">
                  <div style="width: 200px; overflow: hidden; text-overflow: ellipsis">
                    <b>{{ item.name }}</b>
                  </div>
                  <div style="width: 100px">{{ item.code }}</div>
                  <div style="width: 150px">生 日：{{ item.dateOfBirth || '未知' }}</div>
                  <div style="width: 150px; color: red">
                    中文课时：{{ (item.cuDetail && item.cuDetail.chineseRemainingCu) || 0 }}
                  </div>
                  <div style="width: 150px; color: red">
                    数学课时：{{ (item.cuDetail && item.cuDetail.mathRemainingCu) || 0 }}
                  </div>
                  <div style="width: 150px; color: red">
                    英文课时：{{ (item.cuDetail && item.cuDetail.englishRemainingCu) || 0 }}
                  </div>
                </div>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash.debounce';
import storage from 'store';
import {
  postReq,
  getReq,
  createClassLink,
  getSheduleTimeLine as getSheduleTimeLineApi,
  getTalkCloudRoomNumber,
  leaveCheck,
  askForLeave,
  revokeLeave,
} from '@/api/schedule';
import BaseList from '@/components/BaseList/index.vue';
import { ROLES } from '@/store/mutation-types';
import { RolesMap } from '@/utils/const';
import { handleCopy } from '@/utils/util';
import DateLine from './components/DateLine.vue';
import ModalForm from '@/components/ModalForm/index.vue';

const columns = [
  {
    title: '学生姓名',
    dataIndex: 'studentRespDTO.fullName',
    key: 'studentRespDTO.fullName',
    scopedSlots: { customRender: 'studentName' },
  },
  {
    title: '学号',
    dataIndex: 'studentRespDTO.code',
    key: 'studentRespDTO.code',
  },
  {
    title: '国家/地区',
    dataIndex: 'studentRespDTO.country',
    key: 'studentRespDTO.country',
    scopedSlots: { customRender: 'studentRespDTO.country' },
  },
  {
    title: '来源',
    key: 'studentIdentity',
    dataIndex: 'studentIdentity',
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '状态原因',
    key: 'stuScheduleStatusReason',
    dataIndex: 'stuScheduleStatusReason',
    scopedSlots: { customRender: 'stuScheduleStatusReason' },
  },
  {
    title: '出勤',
    key: 'data.attend',
    dataIndex: 'data.attend',
    customRender: (text) => `${text ? '出勤' : '-'}`,
  },
  {
    title: '迟到',
    key: 'data.late',
    dataIndex: 'data.late',
    customRender: (text) => `${text ? '迟到' : '-'}`,
  },
  {
    title: '早退',
    key: 'data.leave_early',
    dataIndex: 'data.leave_early',
    customRender: (text) => `${text ? '早退' : '-'}`,
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

const dataColumns = [
  {
    title: '身份',
    dataIndex: 'identity',
    key: 'identity',
  },
  {
    title: '姓名',
    scopedSlots: { customRender: 'stuName' },
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: '出勤',
    dataIndex: 'attended',
    key: 'attended',
    customRender: (text, record) => {
      if (record.identity === '助教') {
        return '-';
      }
      return `${text ? '出勤' : '-'}`;
    },
  },
  {
    title: '迟到',
    dataIndex: 'lateEntry',
    key: 'lateEntry',
    customRender: (text, record) => `${record.attended && text ? '迟到' : '-'}`,
  },
  {
    title: '早退',
    dataIndex: 'earlyExit',
    key: 'earlyExit',
    customRender: (text, record) => `${record.attended && text ? '早退' : '-'}`,
  },
  {
    title: '时间线',
    scopedSlots: { customRender: 'dateLine' },
  },
];

export default {
  extends: BaseList,
  components: { DateLine, ModalForm },
  name: 'ScheduleDetail',
  data() {
    this.fetchStudent = debounce(this.fetchStudent, 500);
    return {
      leaveModalVisibleUuid: undefined,
      stuLinkLoading: '',
      teaLinkLoading: false,
      currentTab: 'stu',
      RolesMap,
      searchUrl: `/api/admin/student/v2/course_schedule/student`,
      columns,
      dataColumns,
      studentList: [],
      teacherList: [],
      userInfo: storage.get('userInfo'),
      scheduleName: null,
      teacherName: null,
      startDateTime: null,
      endDateTime: null,
      className: null,
      classId: null,
      platform: null,
      status: null,
      courseId: null,
      sectionInfo: {},
      roles: storage.get(ROLES),
      form: {
        courseScheduleId: this.$route.query.id,
        studentName: null,
        code: null,
      },
      dataLoading: false,
      dataPagination: {
        pageSize: 20,
        current: 1,
        total: 0,
      },
      currentClassType: null,
      addStudentVisible: false,
      studentId: null,
      studentOptions: [],

      handleMap: {
        stu: () => this.getListData(1),
        data: () => this.getScheduleTimeLine(1),
      },
      copyLoading: false,
      leaveBtnLoading: false,
      leaveOptions: [
        { label: '学生身体不适', value: 'UNWELL' },
        { label: '学生活动冲突', value: 'ACTIVITY_CONFLICT' },
        { label: '网络或设备故障', value: 'NETWORK_DEVICE_FAILURE' },
        { label: '其他', value: 'OTHER' },
      ],
    };
  },
  watch: {
    currentTab(v) {
      // eslint-disable-next-line no-unused-expressions
      this.handleMap[v]?.();
    },
  },
  computed: {
    showLinkBtn() {
      const access = this.roles.some((item) =>
        [RolesMap.TEACHING_STAFF, RolesMap.TEACHING_STAFF_ADMIN, RolesMap.ADMIN].includes(item),
      );

      const isWukongClass = this.platform === 'TALK_CLOUD';
      const isNormal = this.status === 'NORMAL';

      return access && isWukongClass && isNormal;
    },
  },
  created() {
    getReq(`/api/admin/scheduling/schedule/${this.$route.query.id}`, {}).then((res) => {
      const {
        scheduleName,
        scheduleStatus,
        formClassName,
        formClassId,
        courseSectionRespDTO,
        teacher,
        startDateTime,
        endDateTime,
        platform,
      } = res.data.content;
      this.scheduleName = scheduleName;
      this.className = formClassName;
      this.classId = formClassId;
      this.status = scheduleStatus;
      this.courseId = courseSectionRespDTO.course.uuid;
      this.teacherName = teacher?.fullName;
      this.startDateTime = startDateTime;
      this.endDateTime = endDateTime;
      this.platform = platform;
      this.sectionInfo = res.data.content || {};
    });
  },
  mounted() {},
  methods: {
    async handleRevokeLeave(record) {
      const {
        studentRespDTO: { uuid: studentId },
        leaveId,
      } = record;

      this.$confirmAnt({
        type: 'warning',
        title: '撤销请假',
        content: '撤销请假后，学员将继续跟随原班学习，记得提醒学员参加课程学习哦。',

        onOk: async () => {
          await revokeLeave({ studentId, leaveId });
          this.$message.success('撤销成功');
          this.handleQuery();
        },
      });
    },
    hadnleAdjustschedule(record) {
      const {
        studentRespDTO: { timezoneStd: studentTimezone, uuid: studentId },
        courseScheduleRespDTO: { uuid: courseScheduleId },
        leaveId: leaveItemId,
      } = record;

      this.$router.push({
        path: '/workoutSchedule/adjustScheduleList',
        query: {
          studentTimezone,
          courseScheduleId,
          studentId,
          leaveItemId,
        },
      });
    },
    async handleLeaveFinish(formData, record) {
      const { adjustScheduleProcess, reason } = formData;
      const {
        courseScheduleId,
        studentRespDTO: { uuid: studentId, timezoneStd: studentTimezone },
        courseScheduleRespDTO: { classType },
      } = record;

      try {
        const res = await askForLeave({ courseScheduleId, studentId, reason });

        let msg = '请假完成';
        if (classType === 'PRIVATE') {
          msg = res.data.content.data.urgent
            ? '请假已完成，  本次请假距离开课不足2小时，本节课课时已扣除'
            : '请假已完成， 本次请假在开课前2小时外提交，不扣除课时';
        }

        this.$message.success(msg);

        if (adjustScheduleProcess) {
          this.$router.push({
            path: '/workoutSchedule/adjustScheduleList',
            query: {
              studentTimezone,
              courseScheduleId,
              studentId,
              leaveItemId: res.data.content.leaveId,
            },
          });
        }

        this.handleQuery();
      } finally {
        this.leaveModalVisible = false;
        return true;
      }
    },

    async handleCopyClassLink(userType, uuid, name, record) {
      let str = '';
      let msg = '';

      const params = {
        uuid,
        name,
        courseScheduleId: this.form.courseScheduleId,
        userType,
      };

      switch (userType) {
        case 'TEACHER': {
          this.teaLinkLoading = true;

          str = `${params.name}老师课节链接：`;
          msg = `老师课节链接复制成功`;
          break;
        }
        case 'STUDENT': {
          this.stuLinkLoading = uuid;
          str = `${params.name}(${record.studentRespDTO.code})课节链接（仅支持电脑打开）：`;
          msg = `学员课节链接复制成功`;
          break;
        }
        default:
          break;
      }

      const { data } = await createClassLink(params);
      str += encodeURI(data.content);
      console.log(str);
      await handleCopy(str);
      this.stuLinkLoading = '';
      this.teaLinkLoading = false;

      this.$message.success(msg);
    },

    handleQuery() {
      // eslint-disable-next-line no-unused-expressions
      this.handleMap[this.currentTab]?.();
    },

    async copySectionId() {
      await handleCopy(this.sectionInfo.uuid);
      this.$message.success('复制成功');
    },
    async copyTalkCloudRoomNumber() {
      try {
        this.copyLoading = true;
        const { data } = await getTalkCloudRoomNumber(this.sectionInfo.uuid);
        if (data.content) {
          await handleCopy(data?.content);
          this.$message.success('复制成功');
        } else {
          this.$message.success('复制失败，请联系管理员');
        }
      } catch (error) {
        this.$message.success('复制失败，请联系管理员');
      } finally {
        this.copyLoading = false;
      }
    },
    async getScheduleTimeLine(currentPage) {
      this.dataLoading = true;
      // const { data } = await getSheduleTimeLineApi({
      //   courseScheduleId: this.form.courseScheduleId,
      //   studentProfileReq: {
      //     name: this.form.studentName,
      //     code: this.form.code,
      //     pageNum: currentPage || this.dataPagination.current,
      //     pageSize: this.dataPagination.pageSize,
      //   },
      // });

      const { data } = await getSheduleTimeLineApi(
        {
          studentScheduleSearchReqDTO: {
            courseScheduleId: this.form.courseScheduleId,
            studentName: this.form.studentName,
            code: this.form.code,
            pageSize: 20,
          },
        },
        currentPage || this.dataPagination.current,
      );

      const { content = {} } = data || {};

      const { assistantInfo, studentInfoDTO, teacherInfo } = content;

      teacherInfo.identity = '老师';

      const teacherList = [teacherInfo];
      // eslint-disable-next-line no-unused-expressions
      if (assistantInfo) {
        assistantInfo.identity = '助教';
        teacherList.push(assistantInfo);
      }
      this.teacherList = teacherList;

      const { content: studentList = [], currentPage: current, pageSize, totalCount } = studentInfoDTO;
      this.studentList = studentList.map((item) => ({ ...item, identity: '学生' }));
      this.dataPagination.current = current;
      this.dataPagination.pageSize = pageSize;
      this.dataPagination.total = totalCount;
      this.dataLoading = false;
    },
    studenttableChange(e) {
      this.dataPagination = e;
      this.getScheduleTimeLine();
    },

    cancelStudent(id, name) {
      const $this = this;
      this.$confirmAnt({
        title: '取消学生',
        content: `确定要将 ${name} 取消吗？`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          $this.loading = true;
          postReq(`/api/admin/student/v2/course_schedule/student/cancel/${id}`, {})
            .then(() => {
              $this.getListData();
            })
            .finally(() => {
              $this.loading = false;
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    showAddStudentModal() {
      this.addStudentVisible = true;
      this.studentId = undefined;
    },
    addStudent() {
      this.addStudentVisible = false;
      this.loading = true;
      postReq(`/api/admin/student/v2/course_schedule/student/add/${this.$route.query.id}/${this.studentId}`, {})
        .then(() => {
          this.getListData();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStudent(value) {
      postReq(`/api/admin/student/aggregate_search`, { keyword: value }).then((res) => {
        console.log(res);
        this.studentOptions = res.data;
        console.log(this.studentOptions);
      });
    },
    toStudentDetail(record) {
      this.$store.commit('setStudentName', record.studentName);
      this.$store.commit('setStudentIdRoute', record.studentId);
      this.$router.push({
        path: '/studentDetail',
        query: { uuid: record.studentRespDTO.uuid },
      });
    },

    async handleLeave(record) {
      const {
        studentRespDTO: { uuid: studentId },
        courseScheduleId,
      } = record;

      try {
        this.leaveBtnLoading = true;
        await leaveCheck({ studentId, courseScheduleId });
        this.leaveModalVisibleUuid = record.uuid;
      } finally {
        this.leaveBtnLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.info-wrap {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
}
.info-item {
  margin-right: 50px;
}
.info-name {
  color: rgba(31, 45, 61, 0.7);
}
.info-con {
  color: #000;
}

.ant-form-item {
  margin-bottom: 0px;
}
.class-wrap {
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: #fff;
  padding: 16px 20px;
}
</style>
